import styles from "./styles.module.scss";
import { FC, useState } from "react";
import QuestsInfo from "../QuestsInfo/QuestsInfo";
import { useAppSelector } from "../../../../store/hooks";
import { dateMonthFormatted } from "../../../../helpers/date-MMMM.helper";
import { allGuest } from "../../../../helpers/guest-count.helper";
import { useGuestsInfoByMutation } from "../../../../api/queries/rooms/lastStep.post";
import { useWindowSize } from "../../../../hooks/windowSize";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sumFormatted } from "../../../../helpers/sumFormatted";
import { useTranslation } from "react-i18next";

export interface GuestInput {
  name: string;
  lastName: string;
  fatherName: string;
}

const PaymentBlock: FC = () => {
  const { t } = useTranslation();
  const data = useAppSelector((state) => state.rangePickerReducer);

  const [nameInput, setNameInput] = useState("");
  const [lastNameInput, setLastNameInput] = useState("");
  const [fatherNameInput, setFatherNameInput] = useState("");
  const [mailInput, setMailInput] = useState("");
  const [phoneNumberInput, setPhoneNumberInput] = useState("");

  const [isAgree, setIsAgree] = useState(false);

  const [guests, setGuests] = useState<GuestInput[][]>(() =>
    data.numbers.map((number: any) =>
      Array.from({ length: number.adults + number.childs }, () => ({
        name: "",
        surname: "",
        patronym: "",
      })),
    ),
  );

  const guestsDataRequest = {
    code: data.code,
    email: mailInput,
    guests: guests.flatMap((guest) => guest),
    name: nameInput,
    patronym: fatherNameInput,
    surname: lastNameInput,
    tel: phoneNumberInput,
  };

  const { mutate } = useGuestsInfoByMutation(guestsDataRequest);

  console.log(guests.flatMap((guest) => guest));

  const windowSize = useWindowSize();

  const baseString = `m=662fefa6d8b2a6cf1b679f96;ac.code=${data.code};a=${data.totalPrice * 100}`;

  const { register } = useForm()

  console.log(data);
  let lang;
  if (t("yourRes") == "Your reservation") {
    lang = 0;
  }
  else if (t("yourRes") == "Ваше бронирование") {
    lang = 1;
  }
  else {
    lang = 2;
  }

  return (
    <div className={styles.container}>
      <div className={styles.reservDetails}>
        <div className={styles.result}>
          <h4 className={styles.info}>
            {dateMonthFormatted(data.dates[0], lang)} -{" "}
            {dateMonthFormatted(data.dates[1], lang)},
            {/*{windowSize < 430 && <br />}*/}
            {data.numbers.length} {data.numbers.length < 2 ? t("room") : t("rooms")}
            , {allGuest(data.numbers)}{" "}
            {allGuest(data.numbers).length < 2
              ? t("guest")
              : allGuest(data.numbers).length > 1 &&
                allGuest(data.numbers).length < 5
                ? t("guestRu")
                : t("guestRu")}
          </h4>
          <div className={styles.resultPrice}>
            <h4 className={styles.title}>{t("total")}</h4>
            <h2 className={styles.price}>{sumFormatted(data.totalPrice)}</h2>
          </div>
        </div>
        <div className={styles.detailsBtn}>{t("resDetails")}</div>
      </div>
      <div className={styles.contactDetails}>
        <h2 className={styles.contactData}>{t("contactDetails")}</h2>
        <div className={styles.inputsData}>
          <input
            className={styles.input}
            {...register("name", { required: true })}
            value={nameInput}
            onChange={(e) => setNameInput(e.target.value)}
            placeholder={t("name")}
          />
          <input
            className={styles.input}
            value={lastNameInput}
            onChange={(e) => setLastNameInput(e.target.value)}
            placeholder={t("surname")}
          />
          <input
            className={styles.input}
            value={fatherNameInput}
            onChange={(e) => setFatherNameInput(e.target.value)}
            placeholder={t("fathersName")}
          />
          <input
            className={styles.input}
            value={mailInput}
            onChange={(e) => setMailInput(e.target.value)}
            placeholder={t("mail")}
          />
          <input
            className={styles.input}
            value={phoneNumberInput}
            onChange={(e) => setPhoneNumberInput(e.target.value)}
            placeholder={t("phoneNumber")}
          />
          {/*))}*/}
        </div>
        <div className={classNames(styles.clue, styles.hyphens)} lang="ru">
          {t("reserInfo")}
        </div>
      </div>
      <QuestsInfo guests={guests} setGuests={setGuests} />
      <div className={styles.timeInfo}>
        <h3 className={styles.timeTitle}>{t("checkTime")}</h3>
        <h4 className={styles.timeText}>
          {t("standardTime")}
        </h4>
        <div className={styles.line}></div>
        <h3 className={styles.extraTitle}>{t("addComments")}</h3>
        <div className={styles.extraComment}>
          <p className={styles.text}>
            {t("commentsInfo")}
          </p>
          <textarea className={styles.extraTextArea} />
        </div>
      </div>
      <div className={styles.payment}>
        <div className={styles.leftPart}>
          <h2 className={styles.paymentTitle}>{t("payment")}</h2>
          <div className={styles.agreeWrapper}>
            <div
              className={classNames(styles.agreeBtn, {
                [styles.isAgree]: isAgree,
              })}
              onClick={() => setIsAgree(!isAgree)}
            ></div>
            <p className={styles.agreeText}>
              {t("agreement")}
            </p>
          </div>
          <h4 className={styles.allSumText}>{t("payAll")}</h4>
          <h4 className={styles.paymeText}>
            {t("payProc")}
          </h4>
        </div>
        <div className={styles.rightPart}>
          <div className={styles.imgs}>
            <img
              className={styles.uzcardImg}
              src="/assets/payment/uzcard.png"
              alt="uzcard"
            />
            <img
              className={styles.uzcardImg}
              src="/assets/payment/humo.png"
              alt="humo"
            />
          </div>
          <h4 className={styles.toPayText}>{t("forPayment")}</h4>
          <h3 className={styles.resultSum}>{sumFormatted(data.totalPrice)} {t("sum")}</h3>
          <button className={styles.bookBtn} onClick={() => mutate()}>
            <Link
              to={`https://checkout.paycom.uz/${btoa(baseString)}`}
              className={styles.link}
            >
              {t("book")}
            </Link>
          </button>
        </div>
      </div>
    </div>
  );
};
export default PaymentBlock;

import dayjs from "dayjs";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import "dayjs/locale/uz-latn";



export const dateMonthFormatted = (date: any, lang: any) => {
  if (lang == 0) {
    dayjs.locale("en");
  }
  else if (lang == 1) {
    dayjs.locale("ru");
  }
  else {
    dayjs.locale("uz-latn");
  }
  return dayjs(date, "DD.MM.YY").format("D MMMM");
};

export const dateDayFormatted = (date: any, lang: any) => {
  if (lang == 0) {
    dayjs.locale("en");
  }
  else if (lang == 1) {
    dayjs.locale("ru");
  }
  else {
    dayjs.locale("uz-latn");
  }
  return dayjs(date, "DD.MM.YY").format("dddd");
};

import styles from "./styles.module.scss";
import React, { FC } from "react";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  dateDayFormatted,
  dateMonthFormatted,
} from "../../../../helpers/date-MMMM.helper";
import { priceForType } from "../../../../helpers/priceForType.helper";
import { indexForType } from "../../../../helpers/indexForType.helper";
import { resultSum } from "../../../../helpers/resultSum.helper";
import { dateForDB } from "../../../../helpers/date-YYYY-MM-DD.helper";
import { useNavigate } from "react-router-dom";
import { useRoomsDetailsByMutation } from "../../../../api/queries/rooms/secondStep.post";
import { sumFormatted } from "../../../../helpers/sumFormatted";
import { deleteTypeSelection } from "../../../../store/slices/rangePicker.slice";
import { useTranslation } from "react-i18next";

const ResultReserv: FC = () => {
  const { t } = useTranslation();
  const dates = useAppSelector((state) => state.rangePickerReducer.dates);
  const numbers = useAppSelector((state) => state.rangePickerReducer.numbers);
  const numbersInfo = useAppSelector(
    (state) => state.rangePickerReducer.numbersInfo,
  );
  const roomsData = useAppSelector((state) => state.rangePickerReducer);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Лог для отображения содержимого numbersInfo
  console.log('numbersInfo:', numbersInfo);
  console.log('axaxaxaxaxaxax:  ', t("yourRes"));
  let lang;
  if (t("yourRes") == "Your reservation") {
    lang = 0;
  }
  else if (t("yourRes") == "Ваше бронирование") {
    lang = 1;
  }
  else {
    lang = 2;
  }
  const roomsDetailsRequest = {
    code: roomsData.code,
    date_in: dateForDB(roomsData.dates[0]),
    date_out: dateForDB(roomsData.dates[1]),
    people: roomsData.numbers.map((number: any) => ({
      adults: number.adults,
      childs: number.childs,
      food: number.nutrition,
      mattress: number.mattress,
      room_type: number.type.charAt(0).toUpperCase() + number.type.slice(1),
    })),
  };

  const { isError, mutate } = useRoomsDetailsByMutation(
    roomsDetailsRequest,
    navigate,
    dispatch,
  );

  console.log(isError);
  if (isError) return <div>{isError}</div>;

  return (
    <div className={styles.reservationResult}>
      <h3 className={styles.head}>
        {t("yourRes")}
      </h3>
      <div className={styles.line}></div>
      <div className={styles.dates}>
        <h3
          className={styles.monthDays}
        >{`${dateMonthFormatted(dates[0], lang)} - ${dateMonthFormatted(dates[1], lang)}`}</h3>
        <div className={styles.weekDays}>
          <h4 className={styles.weekDay}>{dateDayFormatted(dates[0], lang)}</h4>
          <h4 className={styles.weekDay}>{dateDayFormatted(dates[1], lang)}</h4>
        </div>
      </div>
      {numbers.some((item: any) => item.type !== "none") && (
        <div className={styles.line}></div>
      )}
      {numbers.map((number: any) => {
        // Добавленные логи для отображения типа номера и всего объекта number
        console.log('number.type:', number.type);
        console.log('number:', number);

        return (
          number.type !== "none" && (
            <div className={styles.info} key={number.index}>
              <div className={styles.typeAndMainSum}>
                <h3 className={styles.type}>{number.type}</h3>
                <p className={styles.mainSum}>
                  {sumFormatted(numbersInfo.data[indexForType(number.type)].price)} {t("sum")}
                </p>
                <button onClick={() => dispatch(deleteTypeSelection({
                  id: number.index - 1,
                  type: number.type.toLowerCase(),
                }))}><img src="/assets/delete.png" alt="delete" style={{ width: "20px" }} /></button>
              </div>
              {new Array(number.mattress)
                .fill(numbersInfo.mattress)
                .map((extra) => {
                  return (
                    <div className={styles.extraSums} key={extra}>
                      <h4 className={styles.extra}>
                        {t("childrenPay")}
                      </h4>
                      <p className={styles.extraSum}>{extra} {t("sum")}</p>
                    </div>
                  );
                })}
              {new Array(number.nutrition)
                .fill(numbersInfo.nutrition)
                .map((extra) => {
                  return (
                    <div className={styles.extraSums} key={extra}>
                      <h4 className={styles.extra}>
                        {t("addNutrition")}
                      </h4>
                      <p className={styles.extraSum}>{extra} {t("sum")}</p>
                    </div>
                  );
                })}
            </div>
          )
        );
      })}
      {numbers.some((item: any) => item.type !== "none") && (
        <>
          <div className={styles.line}></div>
          <h2 className={styles.resultSum}>
            {sumFormatted(resultSum(numbers, numbersInfo, dates) / 100)} {t("sum")}
          </h2>
          <button className={styles.continueBtn} onClick={() => mutate()}>
            {t("continue")}
          </button>
        </>
      )}
    </div>
  );
};

export default ResultReserv;
